<template>
    <div>
        <header class="header-section">
            <div class="container">
                <div class="header-holder">
                    <div class="header-primary d-flex flex-wrap justify-content-between align-items-center">
                        <div class="brand-logo d-none d-lg-inline-block">
                            <div class="logo">
                                <router-link :to="`/dragons/${month.name}`" v-if="month">
                                    <img :src="`/assets/images/dragons/avatar/${month.name}_Dragon.png`"
                                        alt="Dragon Mania Legends Logo" width="100">
                                </router-link>
                                <span>
                                    <span v-for="(element, e_idx) in month.elements" :key="e_idx">
                                        <img :src="`/assets/images/elements/${element}.png`" :alt="`${element}`"
                                            width="25" />
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="header-wrapper justify-content-lg-end">
                            <div class="mobile-logo d-lg-none">
                                <router-link :to="`/dragons/${month.name}`" v-if="month">
                                    <img :src="`/assets/images/dragons/avatar/${month.name}_Dragon.png`"
                                        alt="Dragon Mania Legends Logo" width="100">
                                </router-link>
                            </div>
                            <div class="menu-area">
                                <ul class="menu">
                                    <li>
                                        <router-link :to="`/`">Home</router-link>
                                    </li>
                                    <li><router-link :to="`/events`">Events</router-link></li>
                                    <li>
                                        <router-link :to="`/dragons`">Dragons</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="`/breed-calculator`">Breeding</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="`/how-to-get`">How to Get</router-link>
                                    </li>
                                    <li><router-link :to="`/skills`">Skills</router-link></li>
                                    <li>
                                        <a href="javascript:void(0);">Calculators</a>
                                        <ul class="submenu">
                                            <li><router-link :to="`/damage-calculator`">Damage calculator</router-link>
                                            </li>
                                            <!-- <li><router-link :to="`/enchantment`">Enchantment</router-link></li> -->
                                            <!-- <li><router-link :to="`/economy`">Economy</router-link></li> -->
                                            <li><router-link :to="`/sigils`">Sigils</router-link></li>
                                            <!-- <li><router-link :to="`/raising`">Feeding</router-link></li> -->
                                            <li><router-link :to="`/farm`">Farm</router-link></li>
                                            <li><router-link :to="`/comparison`">Comparison</router-link></li>
                                        </ul>
                                    </li>
                                    <li><a href="https://blog.dragonmania.net" target="_blank">Blog</a></li>
                                </ul>
                                <!-- <a class="wallet-btn" href="https://www.youtube.com/GameXO?sub_confirmation=1" target="_blank">
                                    <span>Game X</span>
                                    <i class="fa-solid fa-youtube"></i>
                                </a> -->

                                <div class="header-bar d-lg-none">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <section class="leaderboard">
            <!-- <div class="container">
                <ins class="adsbygoogle"
                        style="display:block"
                        data-ad-client="ca-pub-6780964513098033"
                        data-ad-slot="9622152699"
                        data-ad-format="auto"
                        data-full-width-responsive="true"></ins>
            </div> -->
            
        </section>

        <router-view :dragons=dragons :elements=elements :events=events :month="month" :week="week"
            :key="$route.fullPath" />

        <section class="leaderboard">
            <!-- <div class="container">
                <ins class="adsbygoogle"
                    style="display:block"
                    data-ad-client="ca-pub-6780964513098033"
                    data-ad-slot="5419453302"
                    data-ad-format="auto"
                    data-full-width-responsive="true"></ins>
            </div> -->


        </section>

        <footer class="footer" style="background-image: url(/assets/images/footer/bg.png);">
            <div class="footer__wrapper padding-top padding-bottom">
                <div class="container">
                    <div class="footer__content text-center">
                        <ul class="social justify-content-center">
                            <li class="social__item">
                                <a href="https://xuyenmandem.com" target="_blank" class="social__link"><i
                                        class="fab fa-twitter"></i></a>
                            </li>
                            <li class="social__item">
                                <a href="https://themecenter.net" target="_blank" class="social__link"><i
                                        class="fab fa-discord"></i></a>
                            </li>
                            <li class="social__item">
                                <a href="https://devicecity.net" target="_blank" class="social__link"><i
                                        class="fab fa-twitch"></i></a>
                            </li>
                            <li class="social__item">
                                <a href="https://cclans.com" class="social__link"><i class="fab fa-instagram"></i></a>
                            </li>
                            <li class="social__item">
                                <a href="https://cclans.com" class="social__link"><i class="fab fa-linkedin-in"></i></a>
                            </li>
                            <li class="social__item">
                                <a href="https://www.facebook.com/sharer.php?u=https://dragonmania.net"
                                    class="social__link"><i class="fab fa-facebook-f"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="footer__copyright">
                <div class="container">
                    <div class="text-center py-4">
                        <p class=" mb-0">Dragon Mania Legends © 2016 | All Rights Reserved </p>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    /*eslint-disable */
    import dragons from './data/dragons'
    import elements from './data/elements'
    import events from './data/events'

    export default {
        name: 'App',
        components: {

        },
        data() {
            return {
                dragons: [],
                elements: [],
                skills: [],
                events: {},
                month: {},
                week: {},
            }
        },
        mounted() {
            this.dragons = dragons.reverse()
            this.elements = elements
            this.events = events
            // this.skills = skills

            this.dragons.map(drg => {
                if (drg.name == this.events.month.name) {
                    this.month = drg
                }

                if (drg.name == this.events.week.name) {
                    this.week = drg
                }
            })

            //const adsbygoogle = window.adsbygoogle || []

            //setTimeout(() => {
           //     adsbygoogle.push({});
          //  }, 2000)

         //   setTimeout(() => {
       //         adsbygoogle.push({});
       //     }, 2000)
        }
    }
</script>
