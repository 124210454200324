/*eslint-disable */

import {
    createStore
} from 'vuex'

// Create a new store instance.
const store = createStore({
    state() {
        return {
            count: 0,
            attackValues: {
                FIRE: 3.0,
                WIND: 1.8,
                EARTH: -1.8,
                WATER: 0,
                PLANT: 1.2,
                METAL: -1.8,
                ENERGY: 5.4,
                VOID: 2.4,
                LIGHT: 3.0,
                SHADOW: 6.0,
                LEGENDARY: 12.0,
                DIVINE: 13.8,
                ANCIENT: 15,
            },
            hpValues: {
                FIRE: -6,
                WIND: -2,
                EARTH: 14,
                WATER: 10,
                PLANT: 8,
                METAL: 16,
                ENERGY: -8,
                VOID: 6,
                LIGHT: 8,
                SHADOW: -6,
                LEGENDARY: 40,
                DIVINE: 42,
                ANCIENT: 45,
            },
            baseAttack: 0,
            baseHP: 0,
            breedTime: '-',
            hatchTime: '-',
        }
    },
    actions: {
        hello() {
            console.log('dml')
        }
    },
    mutations: {
        increment(state) {
            state.count++
        },
        get_base_attack(state, dragon) {
            const elements = dragon.elements
            const el1 = state.attackValues[elements[0]]
            const el2 = elements[1] ? state.attackValues[elements[1]] : 0
            const el3 = elements[2] ? state.attackValues[elements[2]] : 0

            state.baseAttack = Math.round(60 + (el1 + el2 + el3)) || 0
        },
        get_base_hp(state, dragon) {
            const elements = dragon.elements
            const el1 = state.hpValues[elements[0]]
            const el2 = elements[1] ? state.hpValues[elements[1]] : 0
            const el3 = elements[2] ? state.hpValues[elements[2]] : 0

            state.baseHP = Math.round(200 + (el1 + el2 + el3)) || 0
        },
        get_breed_time(state, meta) {
            if (!meta) return '-'
            if (!meta.stats) return '-';

            state.breedTime = meta.stats.breed_time || '-'
        },
        get_hatch_time(state, meta) {
            if (!meta) return '-'
            if (!meta.stats) return '-';

            state.hatchTime = meta.stats.hatch_time || '-'
        },
    }
})

export default store