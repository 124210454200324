import {
    createRouter,
    createWebHistory
} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Dragons.vue')
    },
    {
        path: '/events',
        name: 'Events',
        component: () => import('../views/Events.vue')
    },
    {
        path: '/breed-calculator',
        name: 'BreedCalculator',
        component: () => import('../views/Breeding.vue')
    },
    {
        path: '/how-to-get',
        name: 'HowToGet',
        component: () => import('../views/HowToGet.vue')
    },
    {
        path: '/how-to-get/:dragon',
        name: 'HowToGetADragon',
        component: () => import('../views/HowToGet.vue')
    },
    {
        path: '/calculator',
        name: 'BreedCalculator2',
        component: () => import('../views/Breeding.vue')
    },
    {
        path: '/sigils',
        name: 'Sigils',
        component: () => import('../views/Sigils.vue')
    },
    {
        path: '/sigil',
        name: 'Sigil',
        component: () => import('../views/Sigils.vue')
    },
    {
        path: '/skills',
        name: 'Skills',
        component: () => import('../views/Skills.vue')
    },
    {
        path: '/farm',
        name: 'Farm',
        component: () => import('../views/Farm.vue')
    },
    {
        path: '/dragons/:dragon',
        name: 'Dragon',
        component: () => import('../views/Dragon.vue')
    },
    {
        path: '/dragon/:dragon',
        name: 'OldDragon',
        component: () => import('../views/Dragon.vue')
    },
    {
        path: '/dragons',
        name: 'Dragons',
        component: () => import('../views/Dragons.vue')
    },
    {
        path: '/comparison',
        name: 'Comparison',
        component: () => import('../views/Comparison.vue')
    },
    {
        path: '/damage-calculator',
        name: 'DamageCalculator',
        component: () => import('../views/DamageCalculator.vue')
    },
    {
        path: '/damage-calculator/:dragon',
        name: 'DamageCalculatorDragon',
        component: () => import('../views/DamageCalculator.vue')
    },
    {
        path: "/:pathMatch(.*)*",
        name: 'PageNotFound',
        component: () => import('../views/PageNotFound.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router